var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iotDeviceForm"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update},scopedSlots:_vm._u([{key:"saveBeforeSlot",fn:function(){return [(_vm.isBefore)?_c('v-button',{attrs:{"text":"上一步"},on:{"click":_vm.toBefore}}):_vm._e(),(_vm.isBefore)?_c('v-button',{attrs:{"text":"关闭"},on:{"click":_vm.closeForm}}):_vm._e()]},proxy:true}])},'form-panel',_vm.submitConfig,false),[_c('div',{staticClass:"iotDeviceForm-body"},[_c('div',{staticClass:"iotDeviceForm-t"},[_c('v-steps',{attrs:{"stepData":_vm.stepData,"active":_vm.active}})],1),_c('div',{staticClass:"iotDeviceForm-b"},[(_vm.active == 0)?_c('div',{staticClass:"iotDeviceForm-b-l"},[_c('el-form-item',{attrs:{"label":"选择园区","rules":[
                { required: true, message: '请选择园区', trigger: 'blur' },
              ],"prop":"spaceId"}},[_c('community-select',{attrs:{"specifyCollectionTypes":"","spaceTypes":"10,30"},model:{value:(_vm.form.spaceId),callback:function ($$v) {_vm.$set(_vm.form, "spaceId", $$v)},expression:"form.spaceId"}})],1),_c('el-form-item',{attrs:{"label":"名称","rules":[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ],"prop":"deviceName"}},[_c('v-input',{attrs:{"placeholder":"请输入名称","width":250,"maxlength":300},model:{value:(_vm.form.deviceName),callback:function ($$v) {_vm.$set(_vm.form, "deviceName", $$v)},expression:"form.deviceName"}})],1),_c('el-form-item',{attrs:{"label":"所属产品","rules":[
                { required: true, message: '请选择产品', trigger: 'blur' },
              ],"prop":"productId"}},[_c('v-select',{attrs:{"clearable":"","filterable":"","options":_vm.proList},model:{value:(_vm.form.productId),callback:function ($$v) {_vm.$set(_vm.form, "productId", $$v)},expression:"form.productId"}})],1),_c('el-form-item',{attrs:{"label":"设备唯一编号","rules":[
                {
                  required: true,
                  message: '请输入设备唯一编号',
                  trigger: 'blur',
                },
              ],"prop":"deviceNum"}},[_c('v-input',{attrs:{"placeholder":"请输入设备唯一编号","width":250,"maxlength":500},model:{value:(_vm.form.deviceNum),callback:function ($$v) {_vm.$set(_vm.form, "deviceNum", $$v)},expression:"form.deviceNum"}})],1),_c('div',{staticClass:"title"},[_vm._v("扩展信息")]),_c('el-form-item',{attrs:{"label":"坐标信息","rules":[
                {
                  required: true,
                  message: '请输入坐标信息',
                  trigger: 'blur',
                },
              ],"prop":"extra"}},[_c('span',{staticClass:"label"},[_vm._v("经度")]),_c('v-input',{attrs:{"placeholder":"请输入经度","width":250,"maxlength":500},model:{value:(_vm.form.extra.longitude),callback:function ($$v) {_vm.$set(_vm.form.extra, "longitude", $$v)},expression:"form.extra.longitude"}}),_c('span',{staticClass:"label"},[_vm._v("纬度")]),_c('v-input',{attrs:{"placeholder":"请输入纬度","width":250,"maxlength":500},model:{value:(_vm.form.extra.latitude),callback:function ($$v) {_vm.$set(_vm.form.extra, "latitude", $$v)},expression:"form.extra.latitude"}}),_c('v-button',{attrs:{"text":"地图标注"},on:{"click":_vm.showMap}})],1),_c('el-form-item',{attrs:{"label":"安装区域","rules":[
                {
                  required: true,
                  message: '请输入安装区域',
                  trigger: 'blur',
                },
              ],"prop":"extra"}},[_c('v-input',{attrs:{"clearable":"","placeholder":"请输入安装区域","width":250,"maxlength":500},model:{value:(_vm.form.extra.area),callback:function ($$v) {_vm.$set(_vm.form.extra, "area", $$v)},expression:"form.extra.area"}})],1),_c('el-form-item',{attrs:{"label":"IP地址","rules":[
                {
                  required: true,
                  message: '请输入IP地址',
                  trigger: 'blur',
                },
              ],"prop":"extra"}},[_c('v-input',{attrs:{"clearable":"","placeholder":"请输入IP地址","width":250,"maxlength":500},model:{value:(_vm.form.extra.ip),callback:function ($$v) {_vm.$set(_vm.form.extra, "ip", $$v)},expression:"form.extra.ip"}})],1),_c('el-form-item',{attrs:{"label":"备注","rules":[
                {
                  required: true,
                  message: '请输入备注',
                  trigger: 'blur',
                },
              ],"prop":"extra"}},[_c('v-input',{attrs:{"clearable":"","type":"textarea","placeholder":"请输入备注","width":250,"maxlength":10000},model:{value:(_vm.form.extra.remark),callback:function ($$v) {_vm.$set(_vm.form.extra, "remark", $$v)},expression:"form.extra.remark"}})],1)],1):_vm._e(),(_vm.active == 1)?_c('div',{staticClass:"iotDeviceForm-b-r"},[_c('el-form-item',{attrs:{"label":"设备凭证信息","rules":[
                {
                  required: false,
                  message: '请输入备注',
                  trigger: 'blur',
                },
              ],"prop":"deviceCredentials"}},[_c('v-input',{attrs:{"disabled":"","clearable":"","type":"textarea","width":_vm.width,"maxlength":10000},model:{value:(_vm.deviceCredentials),callback:function ($$v) {_vm.deviceCredentials=$$v},expression:"deviceCredentials"}})],1)],1):_vm._e()])])]),_c('v-map',{attrs:{"selectedLng":Number(_vm.form.longitude),"selectedLat":Number(_vm.form.latitude),"showMapDialog":_vm.showMapDialog},on:{"update:showMapDialog":function($event){_vm.showMapDialog=$event},"update:show-map-dialog":function($event){_vm.showMapDialog=$event},"setLngAndLat":_vm.setLngAndLat}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }